@font-face {
  font-family: 'Copaseti';
  src: url('../font/Copaseti.eot'); /* IE9 Compat Modes */
  src: url('../font/Copaseti.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/Copaseti.woff') format('woff'), /* Modern Browsers */
       url('../font/Copaseti.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../font/Copaseti.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Kaapeli';
  src: url('../font/Kaapeli.eot'); /* IE9 Compat Modes */
  src: url('../font/Kaapeli.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/Kaapeli.woff') format('woff'), /* Modern Browsers */
       url('../font/Kaapeli.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../font/Kaapeli.svg#svgFontName') format('svg'); /* Legacy iOS */
}

// Variables - mainly site colours

$backgroundgrey: #d1d0ce;
$allfontgrey: #98999b;
$buttonblue: #1678C2;
$buttonyellow: #FFE001;
$logoyellow: #FBFD82;
$logoblue: #87D2E7;

// Mixins 

@mixin font($font-size) {font-family: 'Kaapeli', Fallback, sans-serif; font-size: $font-size;} 

@mixin paragraph($font-size) {font-family: 'Kaapeli', Fallback, sans-serif; font-size: $font-size; line-height: 1.8em; color: $allfontgrey; margin-bottom: 0.8em;} 

@mixin slowfade {-webkit-transition: all 500ms ease; -moz-transition: all 500ms ease; -ms-transition: all 500ms ease; -o-transition: all 500ms ease; transition: all 500ms ease;}



// General Hover States 

a { color: $allfontgrey; outline: 0; }
a:link { color: $allfontgrey; } /* unvisited link */
a:hover { color: #f9fb80; } /* mouse over link */
a:active { color: #f9fb80; text-decoration: underline;} /* selected link */
a:visited { color: #f6f6f6;} /* visited link */

h1, h2, h3, h4, h5, h6 {font-family: 'Copaseti', Fallback, sans-serif; color: $allfontgrey;  margin-bottom: 15px;}
p {font-family: 'Kaapeli', Fallback, sans-serif; color: $allfontgrey;}

h1 {font-size: 2.6em;}
h2 {font-size: 2.2em;}
h3 {font-size: 1.8em; margin: 30px 0;}
h4 {font-size: 1.6em;}

p {font-size: 1.2em;}

// Main Styles Nested

body {background-color: $backgroundgrey; font-size: 16px; 

		
	header { position: relative; width: 100%; background-color: #fffefd; height: 200px;

		.head-container {width: 960px; height: 200px; margin: 0 auto;

			img {position: relative; top: 25px; min-height: 150px; z-index: 100;}

			h1 {position: relative; text-align: center; top: -110px; font-size: 2.6em; font-family: 'Kaapeli', Fallback, sans-serif; letter-spacing: 20px; z-index: 1;

				a { text-transform: uppercase; color: $allfontgrey; text-decoration: none;}

				a:hover {color: #88d3e8; @include slowfade;}	
			}
			
			h2 {text-transform: uppercase; color: $allfontgrey; position: relative; text-align: center; top: -110px; font-size: 1.6em; font-family: 'Kaapeli', Fallback, sans-serif; letter-spacing: 11px; z-index: 1;}

			
			nav.respond {display: none;} // Responsive 

			nav.wide {position: relative; width: 100%; top: -80px;

				ul { text-align: right; list-style-type: none; font-family: Copaseti; color: $allfontgrey;

					li {float: right; margin-left: 35px; text-decoration: none; font-size: 1.4em; background-image: url(../img/bullet.png); background-repeat: no-repeat; background-position: 0px 5px; padding-left: 30px; 

						a { color: $allfontgrey; text-decoration: none; letter-spacing: 1px;}
						a:hover { color: #ccc; @include slowfade;}
					}
				}
			}
		}

	} // end header

	.main-content { position: relative;  margin: 0 auto; width: 960px; height: auto; top: 4em; border-top: 5px solid $allfontgrey; border-bottom: 5px solid $allfontgrey;
		

		#shop-container {
			position: relative;
			 max-width: 960px;
			 margin: 0 auto;
			 padding: 0px;
			 height: auto;

			 	img.comein { float: right; margin-top: -70px; } // come in were open image

			 	.topinfo { padding: 5%; 

			 		button {font-family: 'Kaapeli'; font-size: 1em; margin: 0 25px 25px 0; cursor: pointer; padding: 15px 15px 10px 15px; border: 1px solid #4B4B4B; border-radius: 5px; background: $buttonblue; color: #fff; border: none; text-transform: uppercase;-webkit-box-shadow: -3px 3px 0px #959493; -moz-box-shadow: -3px 3px 0px #959493; box-shadow: -3px 3px 0px #959493;}
			 		button:hover { background: #ccc; color: #000; }

			 		ul#filter { font-family: 'Kaapeli';

				 		li {display: inline; margin-right: 10px; line-height: 3em;

				 			a {color: #fff; border-radius: 5px; text-decoration: none; padding: 10px 15px; background: $buttonblue; -webkit-box-shadow: -3px 3px 0px #959493; -moz-box-shadow: -3px 3px 0px #959493; box-shadow: -3px 3px 0px #959493;}
				 			a:hover {background: $buttonyellow; color: #000; @include slowfade}

				 		} // end li

				 		li.current a {background: grey;}

			 		} // end filter

			 		.snipcart-summary {position: relative; top: 0; right: 0; margin: 5px; background: #FFE001; padding: 15px; width: auto; float: right; border-radius: 5px; -webkit-box-shadow: -3px 3px 0px #959493; -moz-box-shadow: -3px 3px 0px #959493; box-shadow: -3px 3px 0px #959493;
						h3 {font-size: 1em; margin: 0; padding: 5px;}
						p {font-size: 1em; margin: 0; padding: 0;}
						button {font-family: 'Kaapeli'; font-size: 0.8em; margin: 0; cursor: pointer; padding: 5px 5px 2px 5px; border: 1px solid #4B4B4B; border-radius: 5px; background: #1678C2; color: #fff; border: none; text-transform: uppercase;-webkit-box-shadow: -1px 1px 0px #959493; -moz-box-shadow: -1px 1px 0px #959493; box-shadow: -1px 1px 0px #959493;}
						button:hover { background: #ccc; color: #000; @include slowfade}
					}

			 	}

			 	p { line-height: 2em; }

				#portfolio {
				 -moz-column-count: 3;
				 -moz-column-gap: 0px;
				 -webkit-column-count: 3;
				 -webkit-column-gap: 0px;
				 column-count: 3;
				 column-gap: 0px;
				 max-width: 960px;
				 padding: 0px;
				 line-height: 0;
				 height: auto;
				 
				 
					li { position: relative; padding: 0; margin: 0; background: #000;
					 	a { 

					 	img { display: inline-block; max-width: 100%; }
					 	
					 	} // end anchor

					 	a:hover { opacity: 0.4; @include slowfade}

					 	button { font-family: Kaapeli; cursor: pointer; position: absolute; bottom: 0px; right: 0px; padding: 5px 10px; border-radius: 5px 0 0 0; background: $buttonyellow; border: none; }
					 	button:hover {  background: #1678C2; color: #fff; box-shadow: 0px 0px 2px #2e2e2e; @include slowfade }

						} // end list item
					} // end shopGallery

		}	// end shop container


		.left {width: 40%; float: left; padding: 5%; 
			
			p  {@include paragraph(1.2em); }
			
			}
		

		.right {width: 40%; float: right; padding: 5%;
			
			img {width: 864px; max-width: 100%;}
			.archive {width: 500px; max-width: 100%; }
			
			#origami {width: 302px; height: 468px; background: url('../img/gallery/folded-paper.png') no-repeat top;
				
				ul.gallery-nav {position: relative; top: 10px; left: 100px; width: 150px; text-align: right;
					li { @include font(1.23em); text-transform: uppercase; line-height: 0.4em; 
						a {text-align: right; }
						a:hover {color: $logoyellow;}
						}
					.current { color: #fff; }
					}  // End Gallery nav


				ul { position: relative; top: 95px; left: 35px; width: 280px; height: 275px; list-style-type: none; line-height: 0.8em;
					li { display: inline; float: left; margin: 5px 15px;
						a { color: $allfontgrey; text-decoration: none;}
						a:hover { color: #ccc; @include slowfade;}
						img {width: 89px; height: 112px;}
						h5 {margin-top: 1px; text-align: center; font-size: 0.8em; font-family: 'Kaapeli', Fallback, sans-serif; text-transform: uppercase;}
					}
				}

				ul.thumbnails { position: relative; top: -135px; left: 50px; width: 200px; height: 200px; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 50px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
				} // End thumbnails

			} // End of origami grid section


			ul.archive-nav {position: relative; top: 0px; left: 0px; width: 100%; margin: 5px; text-align: center;
				li {display: inline;

					a{display: inline; color: #fff; padding: 8px; background-color: $allfontgrey; border-radius: 10px 10px 0 0; text-decoration: none; font-family: 'Kaapeli', Fallback, sans-serif; }
					a:hover {background-color: #b2b2b2;}
					}
					.current {background-color: #b2b2b2; padding: 8px; border-radius: 10px 10px 0 0; font-family: 'Kaapeli', Fallback, sans-serif; color: $allfontgrey; }
				
			}  // End archive nav


			ul.archive-01 { position: relative; top: 0px; left: 0px; width: 400px; height: 350px; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-02 { position: relative; top: 0px; left: 0px; width: 399px; height: 348px; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 133px; height: 116px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-03 { position: relative; top: 0px; left: 0px; width: 400px; height: 200px; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-04 { position: relative; top: 0px; left: 0px; width: 400px; height: 350px; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			} // End all archive gallery styling
		}
	}
	


	footer { position: relative; width: 960px; margin: 0 auto; top: 5em;

		p {text-align: center; font-size: 1em; padding: 10px; font-family: 'Kaapeli', Fallback, sans-serif; color: #b2b2b2; }

	} // end footer

} // end nested content


// Main Contact Form Styles

#contact p, label, legend { font: 1.2em "Kaapeli", Arial, sans-serif; color: $allfontgrey;}

#contact hr { color: inherit; height: 0; margin: 6px 0 6px 0; padding: 0; }

#contact { position: relative; display: block; width: 384px; margin: 0px auto; padding: 2px; top: -20px; }

/* Form style */

#contact label { display: inline-block; float: left; height: 26px; line-height: 26px; width: 155px; font-size: 1.2em; }
#contact input, textarea, select { width: 280px; margin: 0; padding: 5px; color: #666; background: #f5f5f5; border: 1px solid #ccc; margin: 5px 0; font: 1.2em Kaapeli, Arial, sans-serif; }
#contact input:focus, textarea:focus, select:focus { color:#333; }
#contact input.submit { width: 85px; cursor: pointer; background:#333; color:#fff; }
#contact input.submit:hover { background:#444; }
#contact input[type="submit"][disabled] { background:#888; }
#contact fieldset { padding:5px; }
#contact legend { padding:7px 10px; font-weight:bold; color:#000; border:1px solid #eee; margin-bottom:0 !important; margin-bottom:20px; }

#contact span.required{ font-size: 1.2em; color: #ff0000; } /* Select the colour of the * if the field is required. */

#message { margin: 10px 0; padding: 0; }

.error_message { display: block; height: 22px; line-height: 22px; background: #FBE3E4 url('../img/error.gif') no-repeat 10px center; padding: 3px 10px 3px 35px; color:#8a1f11; border: 1px solid #FBC2C4; }

.loader { padding: 0 10px; }

#contact #success_page h1 { position: relative; top: 6px; background: url('../img/success.gif') left no-repeat; padding-left: 22px; font: 1.2em "Kaapeli", Arial, sans-serif; color: $allfontgrey; margin-bottom: 30px;}
#contact #success_page p { line-height: 1.8em;}
acronym { border-bottom:1px dotted #ccc; }




/////////////////// media queries gap so I do not write css in the wrong place //////////////////////





// Media Queries

/* iPads and smaller (portrait and landscape) ----------- */
@media only screen 
and (min-width : 320px) 
and (max-width : 960px) {
/* Styles */
body {font-size: 16px; 


	header { position: relative; width: 100%; background-color: #fffefd; height: 370px;

		.head-container {width: 100%; height: 350px; margin: 0 auto;

			img { display: block; left: -5px; top: 15px; width: 140px; margin: 0 auto;}

			h1 {text-align: center; top: 30px; font-size: 1.8em; font-family: 'Kaapeli', Fallback, sans-serif; letter-spacing: 12px; z-index: 1;

				a { text-transform: uppercase; color: #98999b; text-decoration: none;}

				a:hover {color: #88d3e8; @include slowfade;}

			}

			h2 { top: 40px; font-size: 1em; letter-spacing: 7px; }

			nav.wide {display: none;}
			nav.respond {display: inline; position: relative; width: 100%; top: 60px;

				ul { position: relative; margin: 0 auto; left: -20px; text-align: center; list-style-type: none; font-family: Copaseti; color: #98999b;

					li { line-height: 2em; display: inline; margin-left: 20px; text-decoration: none; font-size: 1.4em; background-image: url(../img/bullet.png); background-repeat: no-repeat; background-position: 0px 5px; padding-left: 20px; 

						a { color: #98999b; text-decoration: none; letter-spacing: 1px;}
						a:hover { color: #ccc; @include slowfade;}
					}
				}
			}
		}

	} // end header

	.main-content {position: relative; overflow: hidden; display: block; width: 95%; top: 5em; border-top: 5px solid #98999b; border-bottom: 5px solid #98999b; margin: 0 auto; 

		#shop-container {
			position: relative;
			 width: 100%;
			 margin: 0 auto;
			 	
			 	img.comein {float: right; margin-top: -70px; } // come in were open image

			 	.topinfo { padding: 5%; 

			 	button {font-family: 'Kaapeli'; font-size: 1em; margin: 0 25px 25px 0; cursor: pointer; padding: 15px 15px 10px 15px; border: 1px solid #4B4B4B; border-radius: 5px; background: #1678C2; color: #fff; border: none; text-transform: uppercase;-webkit-box-shadow: -3px 3px 0px #959493; -moz-box-shadow: -3px 3px 0px #959493; box-shadow: -3px 3px 0px #959493;}
			 		button:hover { background: #FFE001; color: #000; }

			 	} //end top info

			 	p {line-height: 2em;}

					ul#portfolio {
					 -moz-column-count: 2;
					 -moz-column-gap: 0px;
					 -webkit-column-count: 2;
					 -webkit-column-gap: 0px;
					 column-count: 2;
					 column-gap: 0px;
					 width: 100%;
					 padding: 0px;

					 	li { position: relative;
					 	a {

					 	img { display: inline-block; margin-bottom: 0px; max-width: 100%; padding: 0px; }
					 	
					 	} // end anchor

					 	button { cursor: pointer; position: absolute; bottom: 0px; right: 0px; padding: 5px 10px; border-radius: 5px 0 0 0;background: #FFE001; border: none; text-transform: uppercase;}
					 	button:hover {  background: #1678C2; color: #000; box-shadow: 0px 0px 2px #2e2e2e; }

						} // end list item
					} // end shopGallery

		}	// end portfolio

		.left {width: 90%; padding: 5%; text-align: center;
			
			p  {@include paragraph(1.2em)}
			} // end left
		
		.right {width: 90%; padding: 5%;

			img {width: 864px; max-width: 100%; }
			.archive {width: 500px; max-width: 100%; }
			
			a { display: block; text-align: center; }
			a:hover {}
			 

			 
			#origami {width: 302px; height: 468px; background: url('../img/gallery/folded-paper.png') no-repeat top; margin: 0 auto;
				
				ul { position: relative; top: 95px; left: 35px; width: 280px; height: 275px;
					li { display: inline; float: left; margin: 5px 15px; 
						a { color: #98999b; text-decoration: none;}
						a:hover { color: #ccc; @include slowfade;}
						img {width: 89px; height: 112px;}
						h5 {margin-top: 1px; text-align: center; font-size: 1em; font-family: 'Kaapeli', Fallback, sans-serif; text-transform: uppercase;}
					}
				}  // End ul

				ul.thumbnails { position: relative; top: -135px; left: 50px; width: 200px; height: 200px; overflow: hidden;
					li { display: inline; float: left; padding: 0; margin: 0;
						img {width: 50px; height: 50px;}
					}
				}  // End thumbs

			}  // End origami

			ul.archive-nav {position: relative; top: 0px; left: 0px; width: 100%; margin: 5px;
				li {display: inline;

					a{display: inline; color: #fff; padding: 8px; background-color: #98999b; border-radius: 10px 10px 0 0; text-decoration: none; font-family: 'Kaapeli', Fallback, sans-serif; }
					a:hover {background-color: #b2b2b2;}
					}
					.current {background-color: #b2b2b2; padding: 8px; border-radius: 10px 10px 0 0; font-family: 'Kaapeli', Fallback, sans-serif; color: #98999b; }
				
			}  // End archive nav


			ul.archive-01 { position: relative; margin: 0 auto; max-width: 400px; height: 350px; margin: 0 auto; text-align: center; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-02 { position: relative; top: 0px; left: 0px; width: 399px; height: 348px; margin: 0 auto; text-align: center; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 133px; height: 116px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-03 { position: relative; top: 0px; left: 0px; width: 400px; height: 200px; margin: 0 auto; text-align: center; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			}

			ul.archive-04 { position: relative; top: 0px; left: 0px; width: 400px; height: 350px; margin: 0 auto; text-align: center; overflow: hidden;
					li { display: inline-block; float: left; padding: 0; margin: 0;
						img {width: 100px; height: 50px; border: 0px; margin: 0; vertical-align: top;}
					}
			} // End all archive gallery styling

		}  // End Right Content
	}
	footer { width: 75%; 

		p { line-height: 1em; font-size: 0.8em;}

	}
}
#contact label { display: inline-block; height: 26px; line-height: 26px; width: 155px; font-size: 1.2em; }

}


// Media Queries for iphones and small screens

/* iPads and smaller (portrait and landscape) ----------- */
@media only screen 
and (min-width : 320px) 
and (max-width : 720px) {

.maincontent {}
ul#shopGallery {
					 -moz-column-count: 1;
					 -moz-column-gap: 0px;
					 -webkit-column-count: 1;
					 -webkit-column-gap: 0px;
					 column-count: 1;
					 column-gap: 0px;
					 width: 100%;
					 padding: 0px;

img.comein {float: right; margin-top: -70px; display: none;} // come in were open image

}}